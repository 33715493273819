.emptyCart {
  margin: auto;
  text-align: center;
  padding: 10vmax;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.emptyCart > svg {
  font-size: 5vmax;
  color: var(--first-color);
}
.emptyCart > p {
  font-size: 2vmax;
}
.emptyCart > a {
  background-color: rgb(51, 51, 51);
  color: white;
  border: none;
  padding: 1vmax 3vmax;
  cursor: pointer;
  font: 400 1vmax "Roboto";
  text-decoration: none;
}

.cartPage {
  padding: 5vmax;
}

.cartHeader {
  background-color: var(--first-color);
  width: 90%;
  box-sizing: border-box;
  margin: auto;
  color: white;
  min-height: 3vmax;
  display: block;
}
.cartHeader > p {
  margin: 10px;
}
.cartHeader > p:last-child {
  text-align: end;
}

.cartContainer {
  width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
  align-items: center;
}

.cartInput {
  display: flex;
  align-items: center;
  height: 8vmax;
}

.cartInput > button {
  border: none;
  background-color: rgba(0, 0, 0, 0.616);
  padding: 0.5vmax;
  cursor: pointer;
  color: white;
  transition: all 0.5s;
}
.cartInput > button:hover {
  background-color: rgba(0, 0, 0, 0.767);
}

.cartInput > input {
  border: none;
  padding: 0.5vmax;
  text-align: center;
  width: 1vmax;
  min-width: 20px;
  text-align: center;
  outline: none;
  font: 400 1vmax "Roboto";
  color: rgba(0, 0, 0, 0.74);
  -moz-appearance: textfield;
}

.cartInput > input::-webkit-outer-spin-button,
.cartInput > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cartSubtotal {
  display: flex;
  padding: 0.5vmax;
  height: 8vmax;
  align-items: center;
  box-sizing: border-box;
  font: 300 1.3vmax cursive;
  justify-content: flex-end;
  color: rgba(0, 0, 0, 0.753);
}

.cartGrossProfit {
  display: grid;
  grid-template-columns: 2fr 1.2fr;
}

.cartGrossProfitBox {
  border-top: 3px solid var(--first-color);
  margin: 1vmax 4vmax;
  box-sizing: border-box;
  padding: 2vmax 0;
  font: 300 1vmax "Roboto";
  display: flex;
  justify-content: space-between;
}

.checkOutBtn {
  display: flex;
  justify-content: flex-end;
}
.checkOutBtn > button {
  background-color: var(--first-color);
  color: white;
  border: none;
  padding: 0.8vmax 4vmax;
  width: 50%;
  font: 300 0.8vmax "Roboto";
  margin: 1vmax 4vmax;
  cursor: pointer;
  border-radius: 30px;
}

.checkOutBtn > button:hover {
  background-color: var(--first-color);
}
.cartSelectAllContainer {
  border-bottom: 1px solid #ddd;
  padding: 1vmax 0;
  margin-bottom: 1vmax;
}

.selectAllWrapper {
  display: flex;
  align-items: center;
}

.selectAllWrapper label {
  display: flex;
  align-items: center;
  gap: 0.5vmax;
  cursor: pointer;
  font: 300 1vmax "Roboto";
}

.selectAllWrapper input[type="checkbox"] {
  width: 1vmax;
  height: 1vmax;
  cursor: pointer;
}

.clearCartWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.clearCartButton {
  padding: 0.5vmax 1vmax;
  background-color: #ff6b6b;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font: 300 0.9vmax "Roboto";
}

.clearCartButton:hover {
  background-color: #ff5252;
}

.cartControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: auto;
  padding: 0.8vmax 0;
}

.selectAllLabel {
  display: flex;
  align-items: center;
  gap: 0.5vmax;
  cursor: pointer;
  font: 300 0.9vmax "Noto Sans KR";
}

.selectAllLabel input[type="checkbox"] {
  width: 1vmax;
  height: 1vmax;
  cursor: pointer;
}

.removeSelectedBtn {
  display: flex;
  align-items: center;
  gap: 0.4vmax;
  padding: 0.5vmax 1vmax;
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  color: #666;
  cursor: pointer;
  font: 300 0.9vmax "Noto Sans KR";
  transition: all 0.3s ease;
}

.removeSelectedBtn:hover {
  background-color: #f5f5f5;
  border-color: #bfbfbf;
}

.removeSelectedBtn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.removeSelectedBtn svg {
  width: 0.9vmax;
  height: 0.9vmax;
}

@media screen and (max-width: 600px) {
  .selectAllLabel {
    font: 300 1.8vmax "Roboto";
  }

  .selectAllLabel input[type="checkbox"] {
    width: 1.8vmax;
    height: 1.8vmax;
  }

  .removeSelectedBtn {
    font: 300 1.8vmax "Roboto";
    padding: 1vmax 2vmax;
  }

  .removeSelectedBtn svg {
    width: 1.8vmax;
    height: 1.8vmax;
  }
}

@media screen and (max-width: 600px) {
  .cartPage {
    padding: 0;
    min-height: 60vh;
  }

  .cartHeader {
    width: 100%;
    font: 300 1.7vmax "Roboto";
    grid-template-columns: 3fr 1fr 1fr;
  }

  .cartContainer {
    width: 100%;
    grid-template-columns: 3fr 1fr 1fr;
  }

  .cartInput {
    height: 20vmax;
  }

  .cartInput > button {
    padding: 1.5vmax;
  }

  .cartInput > input {
    width: 2vmax;
    padding: 1.5vmax;
    font: 400 1.8vmax "Roboto";
  }

  .cartSubtotal {
    padding: 1.5vmax;
    height: 20vmax;
    font: 300 2vmax "Roboto";
  }

  .cartGrossProfit {
    display: grid;
    grid-template-columns: 0fr 2fr;
  }

  .cartGrossProfitBox {
    padding: 2vmax;
    font: 300 2vmax "Roboto";
  }

  .checkOutBtn > button {
    padding: 2vmax 4vmax;
    width: 100%;
    font: 300 2vmax "Roboto";
  }

  .selectAllWrapper label {
    font: 300 1.8vmax "Roboto";
  }

  .selectAllWrapper input[type="checkbox"] {
    width: 1.8vmax;
    height: 1.8vmax;
  }

  .clearCartButton {
    padding: 1vmax 2vmax;
    font: 300 1.8vmax "Roboto";
  }
}

.swagger-docs-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.swagger-docs-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.confirm-popup {
  position: fixed;
  bottom: 0.2vmax;
  right: 0.2vmax;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0.1vmax 0.2vmax;
  border-radius: 0.05vmax;
  z-index: 1000;
}
